import { render, staticRenderFns } from "./FormList.vue?vue&type=template&id=6fb772b2&scoped=true&"
import script from "./FormList.vue?vue&type=script&lang=js&"
export * from "./FormList.vue?vue&type=script&lang=js&"
import style0 from "./FormList.vue?vue&type=style&index=0&id=6fb772b2&lang=scss&scoped=true&"
import style1 from "./FormList.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./FormList.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb772b2",
  null
  
)

export default component.exports