<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    variant="white"
    opacity="1"
    rounded="sm"
  >
    <b-row v-if="folder">
      <b-col
        cols="12"
        class="mb-2"
      >
        <h2>
          <feather-icon
            class="text-primary ml-1 cursor-pointer"
            icon="ArrowLeftIcon"
            size="21"
            @click="backToPrevPage"
          />
          {{ folder.name }}
        </h2>
      </b-col>
      <b-col cols="12">
        <FormList
          :folder-id="folder.id"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import FormList from '@/views/admin/forms/form-list/FormList.vue'
import useFoldersList from '@/views/components/folders-list/useFoldersList'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    FormList,
  },
  data() {
    return {
      folder: null,
      prevRout: null,
      isLoading: false,
    }
  },
  setup() {
    const {
      fetchFolder,
    } = useFoldersList()

    return {
      fetchFolder,
    }
  },
  async created() {
    try {
      this.isLoading = true
      this.folder = await this.fetchFolder(this.$router.currentRoute.params.id)
    } finally {
      this.isLoading = false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // eslint-disable-next-line no-param-reassign
      vm.prevRout = from
    })
  },
  methods: {
    backToPrevPage() {
      if (this.prevRout.name) {
        this.$router.push({ path: this.prevRout.path, query: this.prevRout.query })
      } else {
        this.$router.push({ name: 'admin-family-form-folders' })
      }
    },
  },
}
</script>

<style>

</style>
